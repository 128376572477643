import { defineMessages, useIntl } from "gatsby-plugin-intl"

export interface IKeyFeature {
  summary: string
  title: string
  image: string
}

function create(title: string, summary: string, image: string): IKeyFeature {
  return { summary, title, image }
}

const messages = defineMessages({
  title1: {
    defaultMessage: "Libraries",
    id: "components.EnterpriseIndustries.f1Title",
  },
  summary1: {
    defaultMessage:
      "Avoid the fate of Alexandria. Rather than microfiche or online servers, create a small Totenpass library to save precious and rare documents.",
    id: "components.EnterpriseIndustries.f1Description",
  },
  title2: {
    defaultMessage: "Academic",
    id: "components.EnterpriseIndustries.f2Title",
  },
  summary2: {
    defaultMessage:
      "Preserve a Nobel prize winning dissertation or a rare manuscript on the physical medium preferred by the Ancients.",
    id: "components.EnterpriseIndustries.f2Description",
  },
  title3: {
    defaultMessage: "Museums",
    id: "components.EnterpriseIndustries.f3Title",
  },
  summary3: {
    defaultMessage:
      "Works of art which define our human culture can find a permanent home in an archive of pure gold.",
    id: "components.EnterpriseIndustries.f3Description",
  },
  title4: {
    defaultMessage: "Government",
    id: "components.EnterpriseIndustries.f4Title",
  },
  summary4: {
    defaultMessage:
      "Create an offline repository of sensitive information which is safe from the impeding threats of cyber warfare.",
    id: "components.EnterpriseIndustries.f4Description",
  },
  title5: {
    defaultMessage: "Corporate",
    id: "components.EnterpriseIndustries.f5Title",
  },
  summary5: {
    defaultMessage:
      "From intellectual property to record-keeping, the Totenpass solution introduces a gold-standard for corporate governance and compliance.",
    id: "components.EnterpriseIndustries.f5Description",
  },
})

export default function useEnterpriseIndustries() {
  const intl = useIntl()

  return [
    create(
      intl.formatMessage(messages.title1),
      intl.formatMessage(messages.summary1),
      "for-libraries.jpg"
    ),
    create(
      intl.formatMessage(messages.title2),
      intl.formatMessage(messages.summary2),
      "for-academic.jpg"
    ),
    create(
      intl.formatMessage(messages.title3),
      intl.formatMessage(messages.summary3),
      "for-museums.jpg"
    ),
    create(
      intl.formatMessage(messages.title4),
      intl.formatMessage(messages.summary4),
      "for-government.jpg"
    ),
    create(
      intl.formatMessage(messages.title5),
      intl.formatMessage(messages.summary5),
      "for-corporate.jpg"
    ),
  ]
}
