import { useFormik } from "formik"
import * as yup from "yup"
import { defineMessages, useIntl } from "gatsby-plugin-intl"
import sdk from "@sdk"
import { useState } from "react"

const errorMessages = defineMessages({
  emailFormat: {
    defaultMessage: "This email is not in a correct format",
    id: "hooks.useContactFormEnterprise.emailFormat",
  },
  emailRequired: {
    defaultMessage: "The email is required",
    id: "hooks.useContactFormEnterprise.emailRequired",
  },
  messageMaxLength: {
    defaultMessage: "Message max length is 500",
    id: "hooks.useContactFormEnterprise.messageMaxLength",
  },
  messageMinLength: {
    defaultMessage: "Message min length is 5",
    id: "hooks.useContactFormEnterprise.messageMinLength",
  },
  nameRequired: {
    defaultMessage: "Your name is required",
    id: "hooks.useContactFormEnterprise.nameRequired",
  },
  companyRequired: {
    defaultMessage: "Your company name is required",
    id: "hooks.useContactFormEnterprise.companyRequired",
  },
  industryRequired: {
    defaultMessage: "Your industry type is required",
    id: "hooks.useContactFormEnterprise.industryRequired",
  },
})

function getInitialValues() {
  return {
    companyname: "",
    email: "",
    industry: "",
    message: "",
    name: "",
  }
}

export default function useContactFormEnterprise() {
  const intl = useIntl()
  const [sent, setSent] = useState(false)
  const validationSchema = yup.object({
    message: yup
      .string()
      .required(intl.formatMessage(errorMessages.messageMinLength))
      .max(500, intl.formatMessage(errorMessages.messageMaxLength))
      .min(5, intl.formatMessage(errorMessages.messageMinLength))
      .trim(),
    email: yup
      .string()
      .required(intl.formatMessage(errorMessages.emailRequired))
      .trim()
      .email(intl.formatMessage(errorMessages.emailFormat)),
    name: yup
      .string()
      .required(intl.formatMessage(errorMessages.nameRequired))
      .trim(),
    company: yup
      .string()
      .required(intl.formatMessage(errorMessages.companyRequired)),
    industry: yup
      .string()
      .required(intl.formatMessage(errorMessages.industryRequired)),
  })
  return {
    form: useFormik({
      initialValues: getInitialValues(),
      onSubmit: async (values, helpers) => {
        helpers.setSubmitting(true)

        await sdk.contacts.subscribe({
          ...values,
          message: `
            ${values.companyname} - ${values.industry}
            \n
            \n
            ${values.message}
          `,
        })

        setSent(true)
        helpers.setSubmitting(false)
      },
      validationSchema,
    }),
    sent,
  }
}
