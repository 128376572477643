import ContactEnterprise from "@components/ContactEnterprise"
import EnterpriseHero from "@components/EnterpriseHero"
import EnterpriseIndustries from "@components/EnterpriseIndustries"
import EnterpriseIndustriesOverview from "@components/EnterpriseIndustriesOverview"
import EnterpriseIntroSection from "@components/EnterpriseIntroSection"
import Reviews from "@components/Reviews"
import Seo from "@components/Seo"
import { enterprisecontacts } from "@routes"
import { PageProps } from "gatsby"
import { defineMessages, useIntl } from "gatsby-plugin-intl"
import React from "react"

const messages = defineMessages({
  description: {
    defaultMessage:
      "Totenpass is a permanent digital storage drive made of solid gold. End your dependence on the cloud, the internet, energy sources, and destructible hardware. Preserve your precious data once and forever.",
    id: "pages.Enterprise.description",
  },
  title: {
    defaultMessage: "Enterprise",
    id: "pages.Enterprise.title",
  },
})

const createIdSection = (route: string) => <div id={route.split("#")[1]} />

export default function EnterprisePage() {
  const intl = useIntl()

  return (
    <>
      <Seo
        description={intl.formatMessage(messages.description)}
        title={intl.formatMessage(messages.title)}
      />
      <EnterpriseHero />
      <EnterpriseIntroSection />
      <EnterpriseIndustriesOverview />
      <EnterpriseIndustries />
      <Reviews />
      {createIdSection(enterprisecontacts)}
      <ContactEnterprise />
    </>
  )
}
