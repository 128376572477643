import React from "react"
import useCssUtil from "@hooks/use-css-util"
import clsx from "clsx"
import { Container, Grid, Typography } from "@material-ui/core"
import { FormattedMessage } from "gatsby-plugin-intl"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { CustomTheme } from "@theme"
import useImageByName from "@hooks/use-image-by-name"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    position: "relative",
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: -1,
      top: 0,
      left: 0,
      width: "100%",
      height: "200px",
      backgroundColor: "#F2EDE4",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "192px",
      paddingBottom: "192px",
      "&::after": {
        width: "34%",
        height: "100%",
      },
    },
  },
  largeText: {
    fontSize: "18px",
  },
  cardImg: {
    width: "100%",
    maxWidth: "464px",
    boxShadow: "0 30px 60px 0 rgba(89, 69, 16, .4)",
    borderRadius: "32px",
  },
  contentContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: "0 !important",
    },
  },
}))

export default function EnterpriseIntroSection() {
  const classes = useCss()
  const cssUtil = useCssUtil()
  const cardImg = useImageByName("tp-enterprise-1.png")

  return (
    <>
      <section className={classes.container}>
        <Container maxWidth="xl">
          <Grid
            alignItems="center"
            justify="center"
            container
            direction="row"
            spacing={10}
          >
            <Grid item xs={12} md={5}>
              <img className={classes.cardImg} src={cardImg?.src} />
            </Grid>

            <Grid item xs={12} md={7} className={classes.contentContainer}>
              <Typography className={cssUtil.mb12} component="h2" variant="h2">
                <FormattedMessage
                  defaultMessage="The Permanent Data Storage Solution for Your Industry’s Archival and Back-up Needs"
                  id="components.EnterpriseIntroSection.title"
                />
              </Typography>
              <Typography
                className={clsx(cssUtil.mb20, classes.largeText)}
                color="textSecondary"
                variant="body1"
              >
                <FormattedMessage
                  defaultMessage="Our institutions have the duty of preserving our most precious cultural and historical information. Totenpass uniquely offers the means of permanently and efficiently storing this data for future generations of committed citizens."
                  id="components.EnterpriseIntroSection.paragraph"
                />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  )
}
