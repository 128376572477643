import useContactFormEnterprise from "@hooks/use-contact-form-enterprise"
import {
  FormHelperText,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { defineMessages, useIntl } from "gatsby-plugin-intl"
import React from "react"
import { ContactContainer } from "./contact/ContactContainer"
import { ContactCTA } from "./contact/ContactCTA"
import { ContactSuccessNotification } from "./contact/ContactSuccessNotification"
import { ContactTitle } from "./contact/ContactTitle"

const useTextAreaCSS = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        marginTop: 0,
      },
      "& .MuiFilledInput-root": {
        display: "flex",
        alignItems: "flex-start",
        border: "none",
        width: "100%",
        height: 130,
        backgroundColor: "#2E2E3B",
        color: "white",
        borderRadius: 6,
        padding: 20,
      },
    },
  })
)

const useCss = makeStyles((theme: CustomTheme) => ({
  formContainer: {
    marginTop: 40,
  },
  input: {
    width: "100%",
    height: 54,
    backgroundColor: "#2E2E3B",
    color: "white",
    borderRadius: 6,
    "&:after, &:before": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      right: "10px",
      color: "#D0D0DD",
    },
  },
  textareaContainer: {
    marginTop: 20,
  },
  emailInputContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0 !important",
    },
  },
  selectPlaceholder: {
    color: "white",
    opacity: "0.4",
  },
}))

//#region i18n

const messages = defineMessages({
  selectindustry: {
    defaultMessage: `Select Industry`,
    id: `components.ContactEnterprise.selectindustry`,
  },
  selectvalue1: {
    defaultMessage: `Libraries`,
    id: `components.ContactEnterprise.selectvalue1`,
  },
  selectvalue2: {
    defaultMessage: `Academic`,
    id: `components.ContactEnterprise.selectvalue2`,
  },
  selectvalue3: {
    defaultMessage: `Museums`,
    id: `components.ContactEnterprise.selectvalue3`,
  },
  selectvalue4: {
    defaultMessage: `Government`,
    id: `components.ContactEnterprise.selectvalue4`,
  },
  selectvalue5: {
    defaultMessage: `Corporate`,
    id: `components.ContactEnterprise.selectvalue5`,
  },
})

//#endregion

export default function ContactEnterprise() {
  const classes = useCss()
  const { form, sent } = useContactFormEnterprise()
  const textareastyles = useTextAreaCSS()
  const { formatMessage } = useIntl()

  return (
    <ContactContainer>
      <ContactTitle />

      <form className={classes.formContainer}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Input
              className={classes.input}
              error={!!form.errors.name}
              name="name"
              inputProps={{ "aria-label": "Name" }}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              placeholder="Your Name"
              value={form.values.name}
            />
            {form.errors.name && (
              <FormHelperText error>{form.errors.name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.emailInputContainer}>
            <Input
              className={classes.input}
              error={!!form.errors.email}
              name="email"
              inputProps={{ "aria-label": "Email" }}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              placeholder="Your Email"
              value={form.values.email}
            />
            {form.errors.email && (
              <FormHelperText error>{form.errors.email}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Input
              className={classes.input}
              error={!!form.errors.companyname}
              name="companyname"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              placeholder="Company Name"
              value={form.values.companyname}
            />
            {form.errors.companyname && (
              <FormHelperText error>{form.errors.companyname}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.emailInputContainer}>
            <Select
              className={classes.input}
              error={!!form.errors.industry}
              displayEmpty
              name="industry"
              inputProps={{ "aria-label": "Industry" }}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              value={form.values.industry}
            >
              <MenuItem value="" disabled>
                <span className={classes.selectPlaceholder}>
                  {formatMessage(messages.selectindustry)}
                </span>
              </MenuItem>
              <MenuItem value="value1">
                {formatMessage(messages.selectvalue1)}
              </MenuItem>
              <MenuItem value="value2">
                {formatMessage(messages.selectvalue2)}
              </MenuItem>
              <MenuItem value="value3">
                {formatMessage(messages.selectvalue3)}
              </MenuItem>
              <MenuItem value="value4">
                {formatMessage(messages.selectvalue4)}
              </MenuItem>
              <MenuItem value="value5">
                {formatMessage(messages.selectvalue5)}
              </MenuItem>
            </Select>
            {form.errors.industry && (
              <FormHelperText error>{form.errors.industry}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.textareaContainer}>
          <Grid item xs={12} className={textareastyles.root}>
            <TextField
              aria-label="Your Message"
              error={!!form.errors.message}
              name="message"
              helperText={form.errors.message}
              multiline
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              placeholder="Your Message"
              rowsMax={4}
              value={form.values.message}
            />
          </Grid>
        </Grid>
        <ContactCTA
          disabled={!form.dirty || form.isSubmitting || !form.isValid}
          onSubmit={form.submitForm}
          submitting={form.isSubmitting}
        />
        <ContactSuccessNotification sent={sent} />
      </form>
    </ContactContainer>
  )
}
