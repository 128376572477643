import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import React from "react"
import KeyFeaturesItem from "./KeyFeaturesItem"
import useEnterpriseIndustries from "@hooks/use-enterprise-industries"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    position: "relative",
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    //marginBottom: theme.custom.sectionSpacingFluid,
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: 0,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "linear-gradient(180deg, #FFFDF9 0%, #F1EDE4 100%)",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      paddingBottom: theme.custom.sectionSpacingLg,
      //marginBottom: theme.custom.sectionSpacingLg,
      "&::after": {
        height: "100%",
      },
    },
  },
}))

export default function KeyFeatures() {
  const classes = useCss()
  const enterpriseIndustries = useEnterpriseIndustries()

  return (
    <section className={classes.container}>
      {enterpriseIndustries.map((industry, index) => (
        <KeyFeaturesItem
          key={index}
          imageDirection={index % 2 === 0 ? "left" : "right"}
          summary={industry.summary}
          title={industry.title}
          image={industry.image}
        />
      ))}
    </section>
  )
}
