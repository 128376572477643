import useCssUtil from "@hooks/use-css-util"
import { Button, Container, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { enterprisecontacts } from "@routes"
import { CustomTheme } from "@theme"
import classNames from "classnames"
import clsx from "clsx"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import React from "react"
import { FnBase } from "tiinvo"
import { Video } from "./Video"

const useCss = makeStyles((theme: CustomTheme) => ({
  hero: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "550px",
    height: "80vw",
    color: "white",
    paddingTop: theme.custom.desktopHeaderHeight,
    "&::after": {
      content: "''",
      position: "absolute",
      zIndex: 0,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: "url(/images/enterprise-hero-bg.jpg)",
      backgroundSize: "cover",
    },
    [theme.breakpoints.up("md")]: {
      height: "700px",
    },
  },
  heroContentContainer: {
    position: "relative",
    zIndex: 1,
    marginBottom: theme.custom.sectionSpacingSm,
  },
  heroTitle: {
    color: theme.custom.lightHeadingTextColor,
  },
  heroDescription: {
    fontSize: "20px",
  },
  ctaContainer: {
    display: "flex",
    justifyContent: "center",
  },
  ctaButton: {
    marginLeft: 15,
    marginRight: 15,
  },
}))

interface EnterpriseHeroProps {
  onPlay?: FnBase
}

export default function EnterpriseHero({ onPlay }: EnterpriseHeroProps) {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <section className={classes.hero}>
      <Container maxWidth="md" className={classes.heroContentContainer}>
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={classNames(cssUtil.mb20, classes.heroTitle)}
        >
          <FormattedMessage
            defaultMessage="Totenpass Enterprise"
            id="components.EnterpriseHero.title"
          />
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={clsx(cssUtil.mb30, classes.heroDescription)}
        >
          <FormattedMessage
            defaultMessage="Totenpass is a permanent digital storage drive made of solid gold. End your dependence on the cloud, the internet, energy sources, and destructible hardware."
            id="components.EnterpriseHero.description"
          />
        </Typography>
        <div className={classes.ctaContainer}>
          <Link to={enterprisecontacts} style={{ textDecoration: "none" }}>
            <Button
              className={classes.ctaButton}
              color="primary"
              variant="contained"
            >
              <FormattedMessage
                defaultMessage="Get in Touch"
                id="components.EnterpriseHero.cta.left"
              />
            </Button>
          </Link>
          <Video />
        </div>
      </Container>
    </section>
  )
}
