import useCssUtil from "@hooks/use-css-util"
import { Container, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { CustomTheme } from "@theme"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import StepBlock from "./StepBlock"

import Enterprise1Icon from "@svg/books-icon.svg"
import Enterprise2Icon from "@svg/edu-icon.svg"
import Enterprise3Icon from "@svg/museum-icon.svg"
import Enterprise4Icon from "@svg/government-icon.svg"
import Enterprise5Icon from "@svg/corporate-icon.svg"

const useCss = makeStyles((theme: CustomTheme) => ({
  container: {
    paddingTop: theme.custom.sectionSpacingFluid,
    paddingBottom: theme.custom.sectionSpacingFluid,
    backgroundImage: "linear-gradient(180deg, #FFFDF9 0%, #F1EDE4 100%)",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.custom.sectionSpacingLg,
      paddingBottom: theme.custom.sectionSpacingLg,
    },
  },
  stepContainer: {
    marginTop: 50,
    marginBottom: 50,
    [theme.breakpoints.up("sm")]: {
      marginTop: 80,
      marginBottom: 80,
    },
  },
  ctaContainer: {
    display: "flex",
    justifyContent: "center",
  },
  description: {
    fontSize: "18px",
  },
}))

export default function EnterpriseIndustriesOverview() {
  const classes = useCss()
  const cssUtil = useCssUtil()

  return (
    <section className={classes.container}>
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={cssUtil.mb20}
        >
          <FormattedMessage
            defaultMessage="Enterprise and Archival Back-Up Solutions"
            id="components.EnterpriseIndustriesOverview.title"
          />
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          className={classes.description}
        >
          <FormattedMessage
            defaultMessage="The Totenpass is the unique solution for all of these industries and beyond."
            id="components.EnterpriseIndustriesOverview.description"
          />
        </Typography>
      </Container>

      <Container maxWidth="lg" className={classes.stepContainer}>
        <Grid container justify="center" spacing={8}>
          <StepBlock
            xs={12}
            sm={6}
            md={4}
            title={
              <FormattedMessage
                defaultMessage="Libraries"
                id="components.EnterpriseIndustriesOverviewBlock.titleOne"
              />
            }
            icon={<Enterprise1Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={4}
            title={
              <FormattedMessage
                defaultMessage="Academic"
                id="components.EnterpriseIndustriesOverviewBlock.titleTwo"
              />
            }
            icon={<Enterprise2Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={4}
            title={
              <FormattedMessage
                defaultMessage="Museums"
                id="components.EnterpriseIndustriesOverviewBlock.titleThree"
              />
            }
            icon={<Enterprise3Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={4}
            title={
              <FormattedMessage
                defaultMessage="Government"
                id="components.EnterpriseIndustriesOverviewBlock.titleFour"
              />
            }
            icon={<Enterprise4Icon />}
          />
          <StepBlock
            xs={12}
            sm={6}
            md={4}
            title={
              <FormattedMessage
                defaultMessage="Corporate"
                id="components.EnterpriseIndustriesOverviewBlock.titleFive"
              />
            }
            icon={<Enterprise5Icon />}
          />
        </Grid>
      </Container>
    </section>
  )
}
